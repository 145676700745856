.section-intro {
    background-color: #063536;
    color: $white;
    padding: 95px 0 30px;

    @include media('≥desktop') {
        padding: 106px 0 90px;
    }

    @include media('≥widescreen') {
        padding: 106px 0 50px;
    }

    p {
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 20px;

        @include media('≥tablet') {
            font-size: 14px;
            line-height: 23px;
            padding-right: 39px;
            margin-bottom: 40px;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        font-size: 12px;
        line-height: 22px;

        li {

            span {
                &:first-child {
                    width: 17px;
                    display: inline-block;
                    text-align: center;
                }

                &:last-child {
                    padding-left: 13px;
                }
            }
        }
    }

    .df-row {

        @include media('≥desktop') {
            margin-bottom: -120px;
        }

        .col {
            &:last-child {
                display: none;

                @include media('≥desktop') {
                    display: block;
                }
            }
        }
    }


    .img-holder-container {
        position: relative;
        display: flex;
        justify-content: flex-end;
        max-width: 430px;
        margin: 0 0 0 auto;

        span {
            width: 375px;
            height: 375px;
            background-color: rgba($color: #cccccc, $alpha: 0.73);
            display: block;
            position: absolute;
            left: 14px;
            top: -11px;
            border-radius: 50%;

            &::before {
                content: '';
                width: 375px;
                height: 375px;
                background-color: rgba($color: #cccccc, $alpha: 0.26);
                display: block;
                position: absolute;
                right: -40px;
                top: 1px;
                border-radius: 50%;
            }

            &::after {
                content: '';
                width: 375px;
                height: 375px;
                background-color: rgba($color: #cccccc, $alpha: 0.26);
                display: block;
                position: absolute;
                right: -50px;
                bottom: -61px;
                border-radius: 50%;
            }
        }
    }

    .img-holder {
        border-radius: 50%;
        width: 430px;
        height: 416px;
        overflow: hidden;
        position: relative;
        right: -10px;

        &::before {
            content: '';
            background: rgb(233, 103, 72);
            background: linear-gradient(133deg, rgba(233, 103, 72, 0.1) 0%, rgba(233, 103, 72, 0.8) 100%);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
}
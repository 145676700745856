.section-list {
    background-color: #fafafa;
    padding: 27px 0 60px;

    @include media('≥desktop') {
        padding: 105px 0 230px;
    }

    .container {
        max-width: 1224px;
    }

    h2 {
        margin-bottom: 22px;

        @include media('≥widescreen') {
            padding-left: 14px;
        }
    }

    .list-items {
        margin: 0 0 30px;
        padding-left: 0;

        @include media('≥desktop') {
            margin: 0 0 117px;
        }

        @include media('≥widescreen') {
            padding-left: 14px;
        }
    }

    .item {
        width: 100%;
        border: 1px solid #dddddd;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 10px;
        margin: 0 0 17px;

        @include media('≥desktop') {
            flex-direction: row;
        }
    }

    .mobile-hidden {
        @include media('<=desktop') {
            display: none !important;
        }
    }

    .mobile-visible {
        display: none !important;

        @include media('<=desktop') {
            display: block !important;
        }
    }

    .holder {
        display: flex;

        @include media('<=desktop') {
            width: 100%;
        }

        &:first-child {
            max-width: 762px;
            padding-right: 15px;

            @include media('<=desktop') {
                max-width: 100%;
                padding-right: 0;
            }

            @include media('<=phone') {
                flex-direction: column;
            }
        }

        &:last-child {
            max-width: 394px;
            width: 100%;

            @include media('<=desktop') {
                max-width: 100%;
                flex-direction: column;
            }
        }
    }

    .logo-holder {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 165px;
        height: 152px;
        border-radius: 10px;
        margin-right: 24px;
        overflow: hidden;

        &.bg-gradient {
            background: linear-gradient(133deg, #ab40e2 0%, #5e2bc6 100%);
        }

        &.bg-orange {
            background: #e77444;
        }

        &.bg-green {
            background: #00a131;
        }

        &.bg-blue {
            background: #2d0379;
        }

        &.bg-black {
            background: #000;
        }

        &.bg-green-dark {
            background: #336c53;
        }
    }

    .text-holder {
        color: #333;
        padding: 7px 3px 0;

        @include media('<=desktop') {
            width: 100%;
        }

        p {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 27px;

            @include media('<=desktop') {
                font-size: 11px;
                line-height: 15px;
                margin-bottom: 15px;
            }
        }

        h3 {
            color: #000;
            margin: 0 0 6px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            width: 100%;

            @include media('<=desktop') {
                justify-content: space-between;
                margin: 0 0 20px;
            }

            li {
                padding-left: 15px;
                border-left: 1px solid #dddddd;
                max-width: 125px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 2px;

                @include media('<=desktop') {
                    padding-left: 8px;
                }

                &:first-child {
                    border-left: none;
                    padding-left: 0;
                }
            }

            span {
                font-size: 11px;
                line-height: 18px;
                color: #777777;
                margin: 0 0 2px;
                display: block;
            }

            strong {
                display: block;
                color: #000;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .bonus-holder {
        width: 165px;
        border: 1px solid #ffb400;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-shrink: 0;

        @include media('<=desktop') {
            width: 100%;
            padding: 13px;
            margin: 0 0 5px;
        }

        strong {
            font-weight: 700;
            font-size: 22px;
            line-height: 18px;
            color: #ffb400;
            display: block;
            margin: 0 0 7px;

            @include media('<=desktop') {
                font-size: 19px;
                line-height: 18px;
            }
        }

        span {
            text-transform: uppercase;
            font-size: 11px;
            line-height: 18px;
            color: #000;

            @include media('<=desktop') {
                font-size: 10px;
            }
        }
    }

    .ctas-holder {
        flex-shrink: 0;
        margin-left: 20px;
        width: 100%;

        @include media('<=desktop') {
            margin-left: 0;
        }

        .btn {
            margin-bottom: 11px;

            svg {
                position: relative;
                top: -2px;
            }
        }
    }

    .rate-holder {
        &-title {
            font-size: 11px;
            line-height: 18px;
            color: #777;
            margin-bottom: 10px;

            @include media('<=desktop') {
                margin-bottom: 7px;
            }
        }

        &-stars {
            display: flex;
            align-items: center;

            .icon {
                width: 30px;
                margin-right: 10px;

                svg {
                    fill: #ffea00;
                }
            }


            strong {
                font-size: 17px;
                line-height: 18px;

                span {
                    font-size: 12px;
                    line-height: 18px;
                    color: #999;
                }
            }

        }
    }


    .description-holder {
        max-width: 1020px;
        margin: 0 auto;

        .text {
            max-width: 849px;
            margin: 0 auto;
            font-size: 14px;
            line-height: 22px;
            color: #000;

            h3 {
                margin-bottom: 12px;
            }

            p {
                margin: 0 0 22px;
            }
        }
    }

    .tablet {
        background-color: #eaf9ff;
        border-radius: 10px;
        overflow: hidden;
        margin: 40px 0 34px;

        .thead {
            .tr {
                border-bottom: 1px solid #d7ebf3;
            }
        }

        .thead {
            .tr {
                padding: 14px 25px;
            }
        }

        .tbody {
            padding: 18px 25px 12px;

            .tr {
                padding: 4px;
                border-bottom: 1px solid #d7ebf3;
            }
        }

        .tr {
            display: flex;
        }

        .th {
            font-size: 14px;
            line-height: 22px;
            font-weight: 700;
            color: #000;
        }

        .td {
            font-size: 12px;
            line-height: 22px;
            color: #000;

            a {
                text-decoration: underline;
                padding-left: 15px;
                color: #000;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .th,
        .td {
            width: 48%;
        }
    }
}


.btn {
    background-color: #1acd02;
    padding: 18px 15px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: $white;
    max-width: 210px;
    width: 100%;
    font-weight: 700;
    border: 1px solid transparent;

    @include media('<=desktop') {
        max-width: 100%;
        justify-content: center;

        span {
            margin-right: 20px;

        }
    }

    &:hover {
        border: 1px solid #1acd02;
        background-color: $white;
        color: #1acd02;
    }
}
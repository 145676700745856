// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site
// -----------------------------------------------------------------------------
[id="header"] {
    position: absolute;
    padding: 11px 0;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;

    @include media('≥tablet') {
        padding: 20px 0;
    }
}

.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
    top: -7px;
    right: -10px;

    @include media('≥tablet') {
        top: -17px;
    }

    li {
        margin-left: 6px;

        &:nth-child(2) {
            margin-left: 7px;
            position: relative;
            top: 3px;

            @include media('≥tablet') {
                margin-left: 18px;
            }
        }
    }
}

.logo {
    width: 100px;
    margin-right: 20px;
}

.nav-opener {
    position: relative;
    width: 30px;
    height: 30px;
    font-size: 0;
    line-height: 0;
    float: right;

    @include media('≥tablet') {
        display: none;
    }

    &:before,
    &:after,
    span {
        @include animate(transform opacity);
        background: $black;
        position: absolute;
        top: 50%;
        left: 15%;
        right: 15%;
        height: 3px;
        transform: translateY(-50%);
    }

    &:before,
    &:after {
        content: '';
    }

    &:before {
        transform: translateY(-9px);
    }

    &:after {
        transform: translateY(6px);
    }

    &:hover {

        &:before,
        &:after,
        span {
            background-color: gray;
        }
    }

    .nav-active & {
        span {
            opacity: 0;
            transform: rotate(45deg);
        }

        &:before {
            transform: rotate(45deg) translateY(0);
        }

        &:after {
            transform: rotate(-45deg) translateY(0);
        }
    }
}
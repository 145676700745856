// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site
// -----------------------------------------------------------------------------
[id="footer"] {
    background: #000;

    color: $white;
    padding: 18px 0;



    .container {
        max-width: 1169px;
    }

    .df-row {
        justify-content: space-between;
        align-items: center;
        margin: 0;

        @include media('<=tablet') {
            flex-direction: column;

        }

        .col {
            &:first-child {
                @include media('<=tablet') {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .copyright {
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;

        p {
            margin: 0 0 1px;
        }
    }

    .footer-nav {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;

        @include media('<=tablet') {
            justify-content: center;
        }

        li {
            margin-right: 10px;
        }

        a {
            text-decoration: underline;
            font-size: 12px;
            line-height: 26px;
            color: $white;


            &:hover {
                text-decoration: none;
            }
        }
    }

    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;
        position: relative;
        top: 0px;
        right: 0px;

        @include media('≥widescreen') {
            right: -10px;
        }

        li {
            margin-left: 15px;

            &:nth-child(2) {
                margin-left: 26px;
                position: relative;
                top: 3px;
            }
        }
    }
}
// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/
// Color system

$white: #fff !default;
$gray: #ddd !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(("100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900),
    $grays);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(("blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800),
    $colors);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(("primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark),
    $theme-colors);

// fonts name
$base-font-sans-serif: "Poppins",
sans-serif;
$base-font-serif: "Poppins",
sans-serif;

$base-font: $base-font-sans-serif;

// Body
$base-text-color: $black !default;
$base-background-body: $white !default;
$base-background-footer: $base-background-body !default;
$font-size-base: 16px !default;
$line-height-base: 1.2 !default;
$font-size-base-lagre: 18px !default;
$line-height-base-lagre: 1.2 !default;
$base-font-family: $base-font !default;
$base-min-width: 320px;

// base variables
$base-radius: 0;
$base-radius-small: 0;

// container settings
$max-width-center-container: 1200px;
$gutter: 15px;
$padding-container: 0 $gutter;

$unit-intervals: ('px': 0.02,
    'em': 0.01,
    'rem': 0.1,
    '': 0);

// grid sistem
$grid: (ph: 'phone',
    sm: 'tablet',
    md: 'desktop',
    lg: 'widescreen'
);

// font weight
$thin: 100;
$light-font: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extra: 800;
$black-font: 900;

// default margins
$vertical-rhythm: $font-size-base+4;
$widget-margin: 20px;

// Liks
$base-link-color: $blue;
$base-link-hover-color: $black;
$text-decoration: none;
$text-decoration-hover: none;

// Headers size
$title-palette: (mobile: ('h1': 22,
        'h2': 19,
        'h3': 17,
        'h4': 14,
        'h5': 14,
        'h6': 14),
    tablet: ('h1': 34,
        'h2': 28,
        'h3': 20,
        'h4': 20,
        'h5': 18,
        'h6': 16),
    desktop: ('h1': 34,
        'h2': 28,
        'h3': 20,
        'h4': 20,
        'h5': 18,
        'h6': 16),
    widescreen: ('h1': 34,
        'h2': 28,
        'h3': 20,
        'h4': 20,
        'h5': 18,
        'h6': 16));

// headings style
$headings-font-family: $base-font-family;
$headings-color: inherit !default;

// Buttons
$btn-border-width: 1px !default;
$btn-border-radius: 5px !default;
$btn-border-radius-sm: calc($btn-border-radius / 2) !default;
$btn-border-radius-lg: calc($btn-border-radius * 2) !default;
$btn-padding: 10px 20px !default;
$btn-padding-sm: 5px 10px !default;
$btn-padding-lg: 20px 40px !default;
$btn-font-size: 15px !default;
$btn-font-size-sm: 12px !default;
$btn-font-size-lg: 18px !default;
$btn-line-height: $btn-font-size+2;
$btn-line-height-sm: $btn-font-size-sm+2;
$btn-line-height-lg: $btn-font-size-lg+2;

// btn default
$btn-color-default: $white !default;
$btn-background-default: $black !default;
$btn-border-color-default: $btn-background-default !default;

// hover
$btn-hover-color-default: $btn-background-default !default;
$btn-hover-background-default: $btn-color-default !default;
$btn-hover-border-color-default: $btn-background-default !default;

// btn primary
$btn-color-primary: $white !default;
$btn-background-primary: $primary !default;
$btn-border-color-primary: $primary !default;
// hover
$btn-hover-color-primary: $white !default;
$btn-hover-background-primary: darken($primary, 10%) !default;
$btn-hover-border-color-primary: $btn-hover-background-primary !default;

// btn secondary
$btn-color-secondary: $white !default;
$btn-background-secondary: $secondary !default;
$btn-border-color-secondary: $secondary !default;
// hover
$btn-hover-color-secondary: $white !default;
$btn-hover-background-secondary: darken($secondary, 10%) !default;
$btn-hover-border-color-secondary: $secondary !default;

// Forms
$input-font-size: $font-size-base; // font size for forms elements
$input-line-height: $input-font-size+2; // line height for forms elements
$input-font-family: $base-font;
$input-padding-vertical: 10px !default; // padding for forms elements
$input-padding-horizontal: 20px !default; // padding for forms elements
$input-padding: $input-padding-vertical $input-padding-horizontal !default;
$input-border-width: 1px !default; // border input's width
$input-border: $input-border-width solid $black !default; // border for forms elements
$input-border-radius: 0 !default; // border radius for forms elements
$inpH: $input-border-width+2 * $input-padding-vertical+$input-line-height;
$input-height: (2 * ($input-border-width + $input-padding-vertical) + $input-line-height) !default; // height for forms elements
//submit
$submit-background: $btn-background-default;
$submit-color: $btn-color-default;
$submit-height: $input-height;
$submit-border-width: $btn-border-width;
$submit-border-style: solid;
$submit-border-color: $btn-border-color-default;
$submit-padding: $btn-padding;
$submit-font-size: $input-font-size;
$submit-line-height: $btn-line-height;
$submit-radius: $btn-border-radius;
// submit hover
$submit-hover-background: $submit-color; // submit background hover
$submit-hover-color: $submit-background; // submit color hover
$submit-hover-border-color: $submit-background; // submit color hover

//focus
$input-focus-border-color: $black !default; // border color for forms elements
//placeholder
$placeholder-color: $gray !default;
// select
$select-height: $input-height;
$select-border: $input-border;
$select-border-radius: $input-border-radius;
$select-padding: $input-padding;
$select-font-size: $input-font-size;
$select-line-height: $input-line-height;

// animation speed
$animation-speed: 0.5s;